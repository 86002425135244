import React from 'react'

interface Props {
  isTruthy: any,
  children: any
}

// tslint:disable-next-line:no-null-keyword
const If: React.SFC<Props> = ({ isTruthy, children }) => isTruthy ? children : null

export default If
