import React from 'react'

import Jumbotron from '../components/Jumbotron/Jumbotron'
import ContentContainer from '../components/ContentContainer/ContentContainer'
import Button from '../components/Button/Button'
import FlexBox, { Justification } from '../components/FlexBox/FlexBox'
import Video from '../components/Video/Video'
import TitledContent from '../components/TitledContent/TitledContent'
import Carousel from '../components/Carousel/Carousel'
import { SlideType } from '../components/CarouselSlide/CarouselSlide'
import Heading, { HeadingLevel, HeadingStyle } from '../components/Heading/Heading'
import FullWidthSection, { PaddingOption } from '../components/FullWidthSection/FullWidthSection'
import Paragraph from '../components/Paragraph/Paragraph'
import MainLayout from '../layouts/MainLayout'
import TickList from '../components/TickList/TickList'
import { COMPANY_NAME } from '../constants'

import jumboImage from './AudioNotetaker/images/audio-notetaker-jumbotron.png'
import gleanImage from './AudioNotetaker/images/glean.png'
import adminToolsImage from './AudioNotetaker/images/admin-tools-image.png'
import afAudioCleanup from './AudioNotetaker/images/advanced features-audio cleanup.png'
import afExport from './AudioNotetaker/images/advanced features-export.png'
import afExtract from './AudioNotetaker/images/advanced features-extract.png'
import afIntegrate from './AudioNotetaker/images/advanced features-integrate.png'
import afAudioLinking from './AudioNotetaker/images/advanced features-linking.png'
import afVoiceShift from './AudioNotetaker/images/advanced features-voice shift.png'

import './AudioNotetaker/AudioNotetaker.scss'

const advancedToolsCarouselSlidesData = [
  {
    title: 'Audio Cleanup',
    subtitle: 'Got some pesky background noise?',
    content: 'Improve the quality of your recording with our powerful audio enhancement tools.',
    summary: 'Find out more about Audio Cleanup',
    src: afAudioCleanup,
    slideType: SlideType.FEATURE
  },
  {
    title: 'Voice Shift',
    subtitle: 'Didn’t catch that?',
    content: 'Alter the pitch and speed of your recording to make speech more accessible.',
    summary: 'Find out more about Voice Shift',
    src: afVoiceShift,
    slideType: SlideType.FEATURE
  },
  {
    title: 'Extract',
    subtitle: 'Overwhelmed?',
    content: 'Cut down the noise by extracting only the most important sections.',
    summary: 'Find out more about Extract',
    src: afExtract,
    slideType: SlideType.FEATURE
  },
  {
    title: 'Integrate',
    subtitle: 'Bit of a tech wizard?',
    content:
      'Sonocent integrates with other accessible tools, like Dragon NaturallySpeaking, to help you make the most of recordings.',
    summary: 'Find out more about Integrate',
    src: afIntegrate,
    slideType: SlideType.FEATURE
  },
  {
    title: 'Export',
    subtitle: 'Ready to study?',
    content:
      'Convert your notes into a range of accessible formats to be used outside of Audio Notetaker as study tools.',
    summary: 'Find out more about Export',
    src: afExport,
    slideType: SlideType.FEATURE
  },
  {
    title: 'Audio-text-linking',
    subtitle: 'Forgotten that moment of inspiration?',
    content: 'Automatically sync your text notes with the audio so you can go back to the spark of your idea.',
    summary: 'Find out more about Audio-text-linking',
    src: afAudioLinking,
    slideType: SlideType.FEATURE
  }
]

const AudioNotetaker: React.SFC = () => (
  <MainLayout title={`${COMPANY_NAME} – Audio Notetaker`}>
    <Jumbotron title="Sonocent Audio Notetaker" image={jumboImage}>
      <Paragraph>Helping our customers get the most out of audio note taking.</Paragraph>
      <div className="app__jumbotron-buttons">
        <Button to="/download" className="app__jumbotron-button">
          Download {'&'} renew licence
        </Button>
      </div>
    </Jumbotron>

    <main>
      <FullWidthSection paddingTop={PaddingOption.NONE} paddingBottom={PaddingOption.TINY}>
        <ContentContainer>
          <TitledContent title="Here's how it works" subtitle="" center titleId="audio-notetaker-demo">
            <Video
              src="https://player.vimeo.com/video/292112600"
              id="audio-notetaker-demo-video"
              className="audio-notetaker__demo-video"
            />
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.SMALL} paddingBottom={PaddingOption.TINY}>
        <ContentContainer>
          <FlexBox justify={Justification.BETWEEN} wrap={false} className="audio-notetaker__an-link-comparison">
            <FlexBox vertical justify={Justification.NORMAL} className="audio-notetaker__an-link-comparison-column">
              <div className="audio-notetaker__an-link-comparison-column__content">
                <Heading
                  headingLevel={HeadingLevel.H2}
                  headingStyle={HeadingStyle.TITLE}
                  className="audio-notetaker__an-link-comparison-column__content__title"
                >
                  Key Features
                </Heading>
                <TickList
                  title="audio-notetaker-for-desktop"
                  noPadding
                  flexColumn
                  className="audio-notetaker__an-link-comparison-column__content__ul"
                >
                  <Paragraph noMargin>Capture audio, text and slides in a single note-taking workspace.</Paragraph>
                  <Paragraph noMargin>Organise and categorise note sets so you can easily return to them.</Paragraph>
                  <Paragraph noMargin>Turn your notes into a variety of formats to suit your learning style.</Paragraph>
                  <Paragraph noMargin>Improve audio quality and extract only what's useful.</Paragraph>
                  <Paragraph noMargin>
                    Enrich notes with drawings, scribbles and photos - perfect for graphs, equations and diagrams.
                  </Paragraph>
                  <Paragraph noMargin>
                    Upload your notes to the software to review and then engage with the new information.
                  </Paragraph>
                </TickList>
              </div>
            </FlexBox>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.NONE} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent title="" subtitle="" className="audio-notetaker__advanced-tools" condensedTitle center>
            <Carousel slides={advancedToolsCarouselSlidesData} />
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.SMALL} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <FlexBox justify={Justification.BETWEEN} wrap={false} className="audio-notetaker__admin-tools">
            <FlexBox vertical justify={Justification.START} className="audio-notetaker__admin-tools__content">
              <Heading
                headingLevel={HeadingLevel.H2}
                headingStyle={HeadingStyle.TITLE}
                className="audio-notetaker__admin-tools__content__title"
              >
                Admin tools
              </Heading>
              <Paragraph>
                Gain access to an intuitive admin portal when you purchase an institutional plan. This makes
                implementing Sonocent and managing your users a breeze.
              </Paragraph>
            </FlexBox>
            <FlexBox justify={Justification.CENTER} className="audio-notetaker__admin-tools__image-container" vertical>
              <img src={adminToolsImage} alt="" />
            </FlexBox>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <FlexBox justify={Justification.BETWEEN} wrap={false} className="audio-notetaker__admin-tools">
            <FlexBox vertical justify={Justification.START} className="audio-notetaker__admin-tools__content">
              <Heading
                headingLevel={HeadingLevel.H2}
                headingStyle={HeadingStyle.TITLE}
                className="audio-notetaker__admin-tools__content__title"
              >
                Have you met Glean? Our new note taking support
              </Heading>
              <Paragraph>
                For 14 years, Sonocent Audio Notetaker has helped students get more out of their lectures and classes.
                Glean is the next generation of audio note taking software.
              </Paragraph>
              <Button to="https://glean.co/institutions" className="app__pill" forGlean>
                Why try Glean?
              </Button>
            </FlexBox>
            <FlexBox justify={Justification.CENTER} className="audio-notetaker__admin-tools__image-container" vertical>
              <img src={gleanImage} alt="" />
            </FlexBox>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent title="The future of Audio Notetaker" center>
            <Paragraph>
              We’ll be supporting Audio Notetaker until 2025. But we’re now encouraging new and existing users to try
              Glean.
            </Paragraph>
            <FlexBox justify={Justification.CENTER} className="home__pills-flexbox">
              <Button
                to="https://help.glean.co/article/260-company-name-change-faqs#general"
                className="app__pill"
                forGlean
              >
                Audio Notetaker FAQs
              </Button>
              <Button to="https://glean.co/why-upgrade-to-glean/" className="app__pill" forGlean>
                Why upgrade?
              </Button>
            </FlexBox>
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>
    </main>
  </MainLayout>
)

export default AudioNotetaker
