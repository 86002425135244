import React from 'react'
import classNames from 'classnames'

import './CarouselArrow.scss'

export enum Direction {
  RIGHT,
  LEFT
}

interface Props {
  direction: Direction
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

const CarouselArrow: React.SFC<Props> = ({ direction, onClick }) => (
  <div
    className={classNames({
      'carousel-arrow': true,
      'carousel-arrow--left': direction === Direction.LEFT,
      'carousel-arrow--right': direction === Direction.RIGHT
    })}
    onClick={onClick}
  >
    <span className="carousel-arrow__span" />
  </div>
)

export default CarouselArrow
