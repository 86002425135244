import React from 'react'
import classNames from 'classnames'

import FlexBox, { Justification } from '../FlexBox/FlexBox'
import If from '../If/If'
import Paragraph from '../Paragraph/Paragraph'


import './CarouselSlideFeatureContent.scss'


interface Props {
  title?: string
  subtitle?: string
  content: string
  src?: string
}

const CarouselSlideFeatureContent: React.SFC<Props> = ({ title, subtitle, content, src }) => (
  <div className="carousel-slide-feature-content">
    <FlexBox justify={Justification.BETWEEN} className="carousel-slide-feature-content__main-content">
      <If isTruthy={src}>
        <div
          className={classNames({
            'carousel-slide-feature-content__main-content__image-container': true,
            'carousel-slide-feature-content__main-content__image-container--no-main-content':
              title === undefined && subtitle === undefined
          })}
        >
          <img src={src} alt="" className="carousel-slide-feature-content__main-content__image-container__image" />
        </div>
      </If>

      <If isTruthy={title || subtitle}>
        <div className="carousel-slide-feature-content__main-content__content-container">
          <If isTruthy={title}>
            <Paragraph
              noFontStyle
              className="carousel-slide-feature-content__main-content__content-container__title"
            >
              <em>{title}</em>
            </Paragraph>
          </If>

          <If isTruthy={subtitle}>
            <Paragraph
              noFontStyle
              className="carousel-slide-feature-content__main-content__content-container__subtitle"
            >
              {subtitle}
            </Paragraph>
          </If>
        </div>
      </If>
    </FlexBox>
    <div className="carousel-slide-feature-content__feature-description-container">
      <Paragraph
        noFontStyle
        className="carousel-slide-feature-content__feature-description-container__description"
      >
        <em>{content}</em>
      </Paragraph>
    </div>
  </div>
)

CarouselSlideFeatureContent.defaultProps = {
  title: undefined,
  subtitle: undefined,
  src: undefined
}

export default CarouselSlideFeatureContent
