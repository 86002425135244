import React from 'react'
import classNames from 'classnames'

import './CarouselIndicator.scss'

interface Props {
  index: number,
  activeIndex: number,
  summary: string,
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const CarouselIndicator: React.SFC<Props> = ({ index, activeIndex, summary, onClick }) => (
  <li className="carousel-indicator">
    <a
      role="button"
      tabIndex={0}
      className={classNames({
        'carousel-indicator__link': true,
        'carousel-indicator__link--active': index === activeIndex
      })}
      onClick={onClick}
      href="#"
      aria-label={summary}
      title={summary}
    />
  </li>
)

export default CarouselIndicator
