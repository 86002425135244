import React from 'react'
import classNames from 'classnames'

import tickIconGreen from './tick-icon-green.png'
import tickIconWhite from './tick-icon-white.png'

import './TickLi.scss'

interface Props {
  primary?: boolean
  small?: boolean
  children: any
}

const TickLi: React.SFC<Props> = ({ primary, small, children }) => (
  <li
    className={classNames('tick-li', {
      'tick-li--small': small
    })}
  >
    <span className="tick-li__span" aria-hidden="true">
      <img src={primary ? tickIconGreen : tickIconWhite} alt="" className="tick-li__span__tick" />
    </span>
    {children}
  </li>
)

TickLi.defaultProps = {
  primary: true,
  small: false
}

export default TickLi
