import React from 'react'
import classNames from 'classnames'

import If from '../If/If'
import CarouselSlideTestimonialContent from '../CarouselSlideTestimonialContent/CarouselSlideTestimonialContent'

import './CarouselSlide.scss'
import CarouselSlideFeatureContent from '../CarouselSlideFeatureContent/CarouselSlideFeatureContent'


export enum SlideType {
  TESTIMONIAL,
  FEATURE
}

interface Props {
  index: number
  activeIndex: number
  slideType: SlideType
  content: string
  title?: string
  subtitle?: string
  author?: string
  occupation?: string
  institution?: string
  src?: string
}

const CarouselSlide: React.SFC<Props> = ({
  index,
  activeIndex,
  title,
  subtitle,
  content,
  author,
  occupation,
  institution,
  src,
  slideType
}) => (
  <div
    className={classNames({
      'carousel-slide': true,
      'carousel-slide--active': index === activeIndex
    })}
  >
    <If isTruthy={slideType === SlideType.TESTIMONIAL}>
      <CarouselSlideTestimonialContent content={content} author={author} occupation={occupation} institution={institution} src={src}/>
    </If>
    <If isTruthy={slideType === SlideType.FEATURE}>
      <CarouselSlideFeatureContent title={title} subtitle={subtitle} content={content} src={src}/>
    </If>
  </div>
)

CarouselSlide.defaultProps = {
  src: undefined
}

export default CarouselSlide
