import React, { ReactNode } from 'react'
import classNames from 'classnames'

import TickLi from './TickLi/TickLi'

import './TickList.scss'

interface Props {
  title: string
  primary?: boolean
  small?: boolean
  noPadding?: boolean
  flexColumn?: boolean
  className?: any
  children: any
}

const TickList: React.SFC<Props> = ({ title, primary, small, noPadding, flexColumn, className, children }) => (
  <ul
    className={classNames('tick-list', {
      [className]: !!className,
      'tick-list--no-padding': noPadding,
      'tick-list--flex-column': flexColumn
    })}
  >
    {children.map((child: ReactNode, i: number) => (
      <TickLi primary={primary} small={small} key={title + `-${i}`}>
        {child}
      </TickLi>
    ))}
  </ul>
)

TickList.defaultProps = {
  primary: true,
  small: false,
  noPadding: false,
  flexColumn: false
}

export default TickList
