import React from 'react'
import classNames from 'classnames'

import FlexBox, { Justification } from '../FlexBox/FlexBox'
import If from '../If/If'
import Paragraph from '../Paragraph/Paragraph'

import './CarouselSlideTestimonialContent.scss'

interface Props {
  content: string
  author?: string
  occupation?: string
  institution?: string
  src?: string
}

const CarouselSlideTestimonialContent: React.SFC<Props> = ({ content, author, occupation, institution, src }) => (
  <FlexBox justify={Justification.BETWEEN} className="carousel-slide-testimonial-content">
    <If isTruthy={src}>
      <div className="carousel-slide-testimonial-content__image-container">
        <img src={src} alt="" className="carousel-slide-testimonial-content__image-container__image" />
      </div>
    </If>

    <FlexBox
      justify={Justification.CENTER}
      vertical
      className={classNames({
        'carousel-slide-testimonial-content__content-container': true,
        'carousel-slide-testimonial-content__content-container--no-image': src === undefined
      })}
    >
      <div className="carousel-slide-testimonial-content__content-container__container">
        <Paragraph
          noFontStyle
          className="carousel-slide-testimonial-content__content-container__container__testimonial"
        >
          <em>"{content}"</em>
        </Paragraph>

        <Paragraph
          noFontStyle
          className="carousel-slide-testimonial-content__content-container__container__info"
        >
          <If isTruthy={author}>
            <span className="carousel-slide-testimonial-content__content-container__container__info__author">
              {author}
            </span>
          </If>
          <If isTruthy={occupation}>
            <span className="carousel-slide-testimonial-content__content-container__container__info__occupation">
              {occupation}
            </span>
          </If>
          <If isTruthy={institution}>
            <span className="carousel-slide-testimonial-content__content-container__container__info__institution">
              {institution}
            </span>
          </If>
        </Paragraph>
      </div>
    </FlexBox>
  </FlexBox>
)

CarouselSlideTestimonialContent.defaultProps = {
  author: undefined,
  occupation: undefined,
  institution: undefined,
  src: undefined
}

export default CarouselSlideTestimonialContent
