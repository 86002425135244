import React from 'react'

import CarouselArrow, { Direction } from '../CarouselArrow/CarouselArrow'
import CarouselIndicator from '../CarouselIndicator/CarouselIndicator'
import CarouselSlide, { SlideType } from '../CarouselSlide/CarouselSlide'
import FlexBox, { Justification } from '../FlexBox/FlexBox'
import If from '../If/If'

import './Carousel.scss'

interface Slide {
  title?: string
  subtitle?: string
  content: string
  author?: string
  occupation?: string
  institution?: string
  summary: string
  src?: any
  slideType: SlideType
}

interface Props {
  slides: Slide[]
}

export default class Carousel extends React.Component<Props> {
  public state = {
    activeIndex: 0
  }

  public onClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault()
    this.goToSlide(index)
  }

  public goToSlide = (index: number) => this.setState({ activeIndex: index })

  public goToPrevSlide = () => {
    let index = this.state.activeIndex
    const { slides } = this.props
    const slidesLength = slides.length

    if (index < 1) {
      index = slidesLength
    }

    --index

    this.goToSlide(index)
  }

  public goToNextSlide = () => {
    let index = this.state.activeIndex
    const { slides } = this.props
    const slidesLength = slides.length - 1

    if (index === slidesLength) {
      index = -1
    }

    ++index

    this.goToSlide(index)
  }

  public render() {
    const { slides } = this.props
    const multipleSlides = slides.length > 1

    return (
      <FlexBox justify={Justification.CENTER} className="carousel">
        <If isTruthy={multipleSlides}>
          <CarouselArrow onClick={this.goToPrevSlide} direction={Direction.LEFT} />
        </If>

        <div className="carousel__slides">
          {slides.map((slide, index) => (
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slideType={slide.slideType}
              title={slide.title}
              subtitle={slide.subtitle}
              content={slide.content}
              author={slide.author}
              occupation={slide.occupation}
              institution={slide.institution}
              src={slide.src}
            />
          ))}
        </div>

        <If isTruthy={multipleSlides}>
          <CarouselArrow onClick={this.goToNextSlide} direction={Direction.RIGHT} />
        </If>

        <If isTruthy={multipleSlides}>
          <ul className="carousel__indicators" aria-label="Testimonial Links">
            {slides.map((slide, index) => (
              <CarouselIndicator
                key={index}
                index={index}
                activeIndex={this.state.activeIndex}
                summary={slide.summary}
                onClick={(event: React.MouseEvent<HTMLElement>) => this.onClick(event, index)}
              />
            ))}
          </ul>
        </If>
      </FlexBox>
    )
  }
}
